import { createContext, useContext } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useMutationAPI } from '@src/util/api';
import { useReservationContext } from '@src/providers/ReservationProvider';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingBackdrop from '@src/components/ui/LoadingBackdrop';
import GuestBook from '@src/models/GuestBook';
import { useForm } from 'react-hook-form';

const Context = createContext({});

export const GuestBookProvider: React.FC = ({ guestBook, children, ...props }) => {
  const { reservation, mutate, isLoading } = useReservationContext();
  const { guestBookId } = useParams();
  guestBook ||= reservation?.findGuestBook({ hashedId: guestBookId });

  const { trigger, ...rest } = useMutationAPI(
    `/v1/checkin/guest_books/${guestBook?.hashedId}`,
    { model: GuestBook }
  );

  const updateGuestBook = (body) => {
    const req = { method: 'PATCH', body };

    return trigger(req).then((guestBook) => {
      reservation.updateGuestBook(guestBook);
      mutate(reservation);
    });
  };

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  return <Context.Provider value={{ guestBook, updateGuestBook, ...rest }} {...props}>
    {children || <Outlet />}
  </Context.Provider>;
};

export const useGuestBookContext = () => useContext(Context);

