import {
  Box,
  Container,
  Link,
  Typography,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';

const AnnotationBanner: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'components.AnnotationBanner' });
  const { reservation } = useReservationContext();

  return (
    <Box bgcolor="background.annotation">
      <Container maxWidth="sm" sx={{ pt: 2, pb: '1px' }}>
        <Typography paragraph variant="body2">{t('p1')}</Typography>
        <Typography paragraph variant="body2">{t('p2')}</Typography>
        <Typography paragraph variant="body2">
          <Link href={reservation?.anshin?.preEntry?.url} color="secondary">
            {t('preEntry')}
            <LaunchIcon sx={{ ml: '2px', verticalAlign: 'middle', fontSize: 'body1.fontSize' }} />
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default AnnotationBanner;

