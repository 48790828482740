export default {
  "title": {
    "primary": "主要宾客",
    "companion": "同行者{{count}}注册",
  },
  "section1": {
    "title": {
      "primary": "主要宾客是否是日本国籍或在日本有地址？",
      "companion": "登记对象是否是日本国籍或在日本有地址？",
    },
    "caption": "如果您不是日本国籍且在日本没有地址，需要注册护照。",
  },
  "section2": {
    "title": {
      "primary": "主要宾客个人信息",
      "companion": "同行者{{count}}的个人信息",
    },
  },
  "section3": {
    "title": "办理入住完成通知邮件设置",
    "description": "在完成办理入住时，您可以通过电子邮件接收有关如何进入房间（房间进入PIN码、房间号码）的信息。",
    "caption": "虽然这些信息可以在办理入住终端上临时显示，但如果您忘记了，通过电子邮件接收将是令人放心的。此设置仅适用于主要宾客。",
  },
  "guest": {
    "name": {
      "label": "姓名（必填）",
      "placeholder": "乐天 太郎",
      "errors": {
        "required": "请输入姓名",
      },
    },
    "address": {
      "label": "地址（必填）",
      "placeholder": "东京都世田谷区玉川1-14-1",
      "errors": {
        "required": "请输入地址",
      },
    },
    "phoneNumber": {
      "label": "联系电话号码（必填）",
      "defaultCountry": "cn",
      "errors": {
        "required": "请输入联系电话号码",
        "invalid": "请输入有效的电话号码",
      },
    },
    "checkinNotification": {
      "label": "您是否希望接收邮件？（必填）",
      "errors": {
        "required": "请选择",
      },
    },
    "email": {
      "label": "电子邮件地址（必填）",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "请输入电子邮件地址",
        "invalid": "请输入有效的电子邮件地址",
      },
    },
  },
  "sameWithPrimaryGuest": "与主要宾客相同",
  "register": "注册",
};
