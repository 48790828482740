import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

const RadioButton = styled(({ label, value, selected, ...props }) => (
  <Button
    variant="outlined"
    disableRipple
    tabIndex={-1}
    {...props}
  >
    <FormControlLabel
      label={label}
      value={value}
      control={<Radio tabIndex={0} />}
    />
  </Button>
))(({ theme, selected }) => ({
  backgroundColor: selected ? `${theme.palette.background.selected} !important` : '',
}));

export default RadioButton;

