export default {
  "Reservation information": "Reservation Information",
  "Guest information": "Guest Information",
  "cancel": "Cancel",
  "save": "Save",
  "yes": "Yes",
  "no": "No",
  "back": "Back",
  "today": "today",
  "components": {
    "ReservationSummary": {
      "title": "Reservation Details",
      "failed": "Failed to retrieve reservation information. Please reload the page and try again.",
      "name": "Guest Name",
      "bookingNumber": "Booking Number",
      "property": "Accommodation",
      "numberOfRooms": "Number of Rooms",
      "checkin": "Check-in",
      "checkout": "Check-out",
    },
    "LockScreen": {
      "greeting": "Welcome to Rakuten STAY.\n\nPlease register guest information here before check-in.",
      "found": "Reservations for check-in on {{checkin}} have been found.\n\nIf the reservation details are correct, please proceed to register guest information.",
      "Form": {
        "passCode": "Passcode",
        "helperText": "Please enter the passcode provided in the email or message.",
        "invalid": "Invalid passcode.",
        "submit": "Submit",
        "register": "Proceed to Register Guest Information",
      },
    },
    "Root": {
      "greeting": "Welcome to Rakuten STAY.",
      "description": "To proceed with check-in, please register guest information here.",
      "Form": {
        "property": "Accommodation",
        "bookingNumber": "Booking Number",
        "bookingNumberHelp": "Enter the booking number issued by the reservation site. The booking number is provided in the confirmation email.",
        "submit": "Confirm Reservation",
        "notFound": "No matching reservation found.",
      },
    },
    "AnnotationBanner": {
      "p1": "This service is currently in trial operation and is only available to Japanese individuals or individuals residing in Japan.",
      "p2": "If there are guests with foreign nationality and no address in Japan among the guests, the conventional check-in method will be applied. Please access the following link to register your passport and pre-register the guest list.",
      "preEntry": "Pre-registration of Guest List (Conventional Method)",
    },
    "Footer": {
      "privacyPolicy": {
        "label": "Privacy Policy",
        "href": "https://corp.stay.rakuten.co.jp/privacy-en.html",
      },
      "agreement": {
        "label": "Rakuten STAY Accommodation Clause",
        "href": "https://stay.rakuten.co.jp/agreement/en.html"
      },
    },
  },
  "checkinStatus": {
    "pre-entry": "Guest Information Registration in Progress",
    "submitted": "Guest Information Registration Completed",
    "checked-in": "Checked-in",
    "checked-out": "Checked-out",
  },
};
