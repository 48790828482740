import React, { useEffect, useState } from 'react';
import {
  Box,
  FormHelperText,
  TextField,
} from '@mui/material';
import SubmitButton from '@src/components/buttons/SubmitButton';
import SearchIcon from '@mui/icons-material/Search';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inputRef } from '@src/util/form';
import { useAPI, useMutationAPI } from '@src/util/api';
import { useForm } from 'react-hook-form';
import Shake from '@src/components/ui/Shake';
import { useQuery } from '@src/util/router';

const Form: React.FC = (props) => {
  const { t } = useTranslation("common", { keyPrefix: "components.Root.Form" });
  const { id } = useParams();
  const { register, setValue, setError, handleSubmit, formState: { isSubmitting, errors } } = useForm();
  const navigate = useNavigate();
  const [shake, setShakeStatus] = useState(false);

  const { data } = useAPI(
    `/v1/checkin/properties/${id}`
  );

  const { trigger } = useMutationAPI('/v1/checkin/reservations');

  const onSubmit = (query) => {
    setShakeStatus(false);

    return trigger({ method: 'GET', query }).then(([reservation]) => {
      if (reservation) {
        navigate(`/${reservation.hashedId}`, { state: reservation });
      } else {
        setShakeStatus(true);
        setError("bookingNumber", { type: 'custom', message: t('notFound') });
      }
    });
  };

  useEffect(() => {
    setValue('propertyId', id);
  }, [id]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} {...props}>
      <TextField
        label={t('property')}
        variant="standard"
        select
        SelectProps={{
          native: true,
        }}
        InputLabelProps={{ shrink: true }}
        size="large"
        fullWidth
        sx={{ mt: 2, mb: 4 }}
        {...inputRef(register('propertyId', { required: true }))}
      >
        {data?.length && <option value="" />}
        {[].concat(data || []).map(prop => (
          <option key={prop.id} value={prop.id}>{prop.localized('title')}</option>
        ))}
      </TextField>

      <Shake in={shake}>
        <TextField
          autoFocus
          fullWidth
          required
          label={t('bookingNumber')}
          inputProps={{ tabIndex: 1 }}
          InputProps={{
            sx: {
              'legend': { fontSize: 'caption.fontSize' },
            }
          }}
          sx={{ bgcolor: '#fff' }}
          {...inputRef(register('bookingNumber', { required: true }))}
        />
      </Shake>

      <FormHelperText error={!!errors.bookingNumber?.message}>{errors.bookingNumber?.message || t('bookingNumberHelp')}</FormHelperText>

      <Box maxWidth={240} mx="auto" mt={4}>
        <SubmitButton
          startIcon={<SearchIcon />}
          loading={isSubmitting}
        >{t("submit")}</SubmitButton>
      </Box>
    </Box>
  );
};

export default Form;
