import {
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import Header from '@src/routes/LockScreen/Header';
import Footer from '@src/components/ui/Footer';
import LanguageSwitcher from '@src/components/ui/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import Form from './Form';

const Root: React.FC = () => {
  const { t } = useTranslation("common", { keyPrefix: "components.Root" });

  return (<>
    <LanguageSwitcher />

    <Container maxWidth="sm" sx={{ mt: { xs: '16px', sm: '10vh' } }}>
      <Card variant="outlined">
        <Container maxWidth="xs">
          <Header my={3} />

          <Typography paragraph variant="body1">{t('greeting')}</Typography>
          <Typography paragraph variant="body1">{t('description')}</Typography>

          <Form mb={4} />
        </Container>
      </Card>

      <Footer my={4} />
    </Container>
  </>);
};

export default Root;

