export default {
  "title": {
    "primary": "Primary Guest",
    "companion": "Companion {{count}} Registration",
  },
  "section1": {
    "title": {
      "primary": "Is the primary guest a Japanese national or does he/she have an address in Japan?",
      "companion": "Is the registered person a Japanese national or does he/she have an address in Japan?",
    },
    "caption": "If you are not a Japanese national and do not have an address in Japan, passport registration is required.",
  },
  "section2": {
    "title": {
      "primary": "Primary Guest's Personal Information",
      "companion": "Companion's Personal Information",
    },
  },
  "section3": {
    "title": "Check-in Completion Notification Email Settings",
    "description": "You can receive information on how to enter your room (room entry PIN, room number) by email when check-in is complete.",
    "caption": "While this information can be temporarily displayed on the check-in terminal, it is reassuring to receive it by email in case you forget. This setting is available only for the primary guest.",
  },
  "guest": {
    "name": {
      "label": "Name (Required)",
      "placeholder": "Rakuten Taro",
      "errors": {
        "required": "Please enter your name",
      },
    },
    "address": {
      "label": "Address (Required)",
      "placeholder": "1-14-1 Tamagawa, Setagaya-ku, Tokyo",
      "errors": {
        "required": "Please enter your address",
      },
    },
    "phoneNumber": {
      "label": "Contact Phone Number (Required)",
      "defaultCountry": "us",
      "errors": {
        "required": "Please enter your contact phone number",
        "invalid": "Please enter a valid phone number",
      },
    },
    "checkinNotification": {
      "label": "Do you want to receive emails? (Required)",
      "errors": {
        "required": "Please make a selection",
      },
    },
    "email": {
      "label": "Email Address (Required)",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "Please enter your email address",
        "invalid": "Please enter a valid email address",
      },
    },
  },
  "sameWithPrimaryGuest": "Same as Primary Guest",
  "register": "Register",
};
