export default {
  "Reservation information": "予約情報",
  "Guest information": "宿泊者情報",
  "cancel": "キャンセル",
  "save": "保存",
  "yes": "はい",
  "no": "いいえ",
  "back": "戻る",
  "today": "本日",
  "components": {
    "ReservationSummary": {
      "title": "ご予約内容",
      "failed": "予約情報の取得に失敗しました。ページを再読込みして再度お試しください。",
      "name": "予約者名",
      "bookingNumber": "予約番号",
      "property": "宿泊施設",
      "numberOfRooms": "部屋数",
      "checkin": "チェックイン",
      "checkout": "チェックアウト",
    },
    "LockScreen": {
      "greeting": "ようこそRakuten STAYへ\n\nチェックインまでにこちらから宿泊者情報のご登録をお願いします。",
      "found": "{{checkin}}チェックインの予約が見つかりました。\n\nご予約内容に間違いがなければ、宿泊者情報の登録へ進んでください。",
      "Form": {
        "passCode": "パスコード",
        "helperText": "事前にお送りしているメールまたはメッセージに記載されているパスコードを入力してください。",
        "invalid": "パスコードが正しくありません。",
        "submit": "送信",
        "register": "宿泊者情報の登録に進む",
      },
    },
    "Root": {
      "greeting": "ようこそRakuten STAYへ",
      "description": "チェックインを進めるために、こちらから宿泊者情報のご登録をお願いします。",
      "Form": {
        "property": "宿泊施設",
        "bookingNumber": "予約番号",
        "bookingNumberHelp": "予約サイトで発行される予約番号を入力してください。予約番号は予約完了メールなどに記載されています。",
        "submit": "予約を確認する",
        "notFound": "該当する予約が見つかりませんでした。",
      },
    },
    "AnnotationBanner": {
      "p1": "こちらのサービスは現在試験運用中のため、日本人および日本に住所のある方のみに対応しています。",
      "p2": "ご宿泊者の中に、日本国外の国籍かつ日本に住所のない方がいる場合は従来型でのチェックイン方法となりますので、次のリンク先にアクセスしてパスポートの登録および宿泊者名簿の事前登録をお願いします。",
      "preEntry": "宿泊者名簿の事前登録（従来型）",
    },
    "Footer": {
      "privacyPolicy": {
        "label": "個人情報保護方針",
        "href": "https://corp.stay.rakuten.co.jp/privacy.html",
      },
      "agreement": {
        "label": "Rakuten STAY 宿泊約款",
        "href": "https://stay.rakuten.co.jp/agreement/ja.html"
      },
    },
  },
  "checkinStatus": {
    "pre-entry": "宿泊者情報登録中",
    "submitted": "宿泊者情報登録完了",
    "checked-in": "チェックイン済み",
    "checked-out": "チェックアウト済み",
  },
};
