import React from 'react';
import {
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import Header from './Header';
import Footer from '@src/components/ui/Footer';
import LanguageSwitcher from '@src/components/ui/LanguageSwitcher';
import Form from './Form';
import ReservationSummary from '@src/components/ui/ReservationSummary';
import { useLocation } from 'react-router-dom';
import { nl2br } from '@src/util/string';
import dayjs from 'dayjs';

const LockScreen: React.FC = () => {
  const { t } = useTranslation("common", { keyPrefix: "components.LockScreen" });
  const { state } = useLocation();
  const { reservation } = useReservationContext();

  let message = t('greeting');

  if (state?.authCode && reservation) {
    const checkin = reservation.checkinIsToday ?
      t('common:today') :
      dayjs(reservation.checkin).format('ll');

    message = t('found', { checkin })
  }

  return (<>
    <LanguageSwitcher />

    <Container maxWidth="sm" sx={{ mt: { xs: '16px', sm: '10vh' } }}>
      <Card variant="outlined">
        <Container maxWidth="xs">
          <Header my={3} />

          {message.split("\n\n").map((s, i, a) => (
            <Typography key={i} variant="body1" paragraph>{nl2br(s)}</Typography>
          ))}

          <ReservationSummary mt={3} />

          <Form mt={3} mb={4} authCode={state?.authCode} />
        </Container>
      </Card>

      <Footer my={4} />
    </Container>
  </>);
};

export default LockScreen;

