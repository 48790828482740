import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import H3 from '@src/components/typography/H3';
import ListItemAddButton from '@src/components/buttons/ListItemAddButton';
import { Link } from 'react-router-dom';
import EKYCStatus from './EKYCStatus';

const PrimaryGuest: React.FC = () => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.PrimaryGuest' });
  const { reservation } = useReservationContext();
  const { guestBook: { hashedId, primaryGuest } = {}} = useGuestBookContext();

  const href = `/${reservation.hashedId}/${hashedId}/primary-guest`;

  return (<>
    <H3>{t('title')}</H3>
    <List>
      {primaryGuest.status == 'unready' ? (<>
        <ListItem disablePadding>
          <ListItemAddButton component={Link} to={href}>
            {t('register')}
          </ListItemAddButton>
        </ListItem>
      </>) : (<>
        <ListItem
          secondaryAction={
            <Button color="secondary" endIcon={<ChevronRightIcon />} component={Link} to={href}>{t('update')}</Button>
          }
        >
          <ListItemText>
            <Typography fontWeight={700}>{primaryGuest.name}</Typography>
          </ListItemText>
        </ListItem>
        <EKYCStatus />
      </>)}
    </List>
  </>);
};

export default PrimaryGuest;

