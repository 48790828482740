import Typography from '@mui/material/Typography';

const H3 = (props) => (
  <Typography
    component="h3"
    bgcolor="grey.100"
    py={0.5}
    px={1.5}
    {...props}
  />
);

export default H3;

