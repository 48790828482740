export default {
  "guestBook": "투숙객 명부",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "체크인 전에 모든 투숙객 정보 등록을 완료하십시오.",
      },
      "submitted": {
        "message": "체크인이 준비되었습니다.\n체크인 접수 시작 시간은 {{checkinTime}}부터입니다.",
      },
      "checked-in": {
        "message": "이 예약은 체크인이 완료되었습니다.\n\n객실 번호 또는 룸 키를 잊어버린 경우, 이 화면에 표시된 이차원 코드 또는 체크인 번호를 사용하여 그 재표시 작업을 체크인 단말기에서 수행하십시오.",
      },
      "checked-out": {
        "message": "Rakuten STAY에서 숙박해 주셔서 진심으로 감사합니다.\n\n다음 이용을 기대하고 있습니다.",
      },
    },
    "NumberOfGuests": {
      "total": "투숙객 총 인원: {{count}}명",
      "registered": "<0>등록된 인원: <1>{{count}}명</1></0>",
      "description": "투숙객 수를 등록하십시오.",
      "caption": "등록된 모든 투숙객에 대한 정보를 등록하십시오.",
      "update": "수정",
      "numberOfGuestsWithCount": "{{count}} 명",
    },
    "PrimaryGuest": {
      "title": "주 투숙객",
      "register": "등록",
      "update": "편집",
    },
    "EKYCStatus": {
      "description": "체크인 시 본인 확인을 위해 얼굴 사진을 등록해 주십시오.",
      "register": "얼굴 등록",
      "update": "재등록",
      "submitted": "얼굴 사진 등록 완료",
      "failed": "얼굴 사진 등록 상태를 가져올 수 없습니다. 페이지를 다시 로드하십시오.",
    },
    "CheckinCode": {
      "description": "이 체크인 단말기 (SMART CHECK-IN) 에서 이 QR 코드 또는 체크인 번호를 사용하여 체크인을 진행해 주세요.",
      "label": "체크인 번호",
    },
    "AccompanyingGuests": {
      "title_zero": "동반 투숙객",
      "title": "동반 투숙객 {{count}}",
      "register": "등록",
      "update": "편집",
    },
  },
};
