import React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import Caption from '@src/components/typography/Caption';
import Select from '@mui/material/Select';
import range from 'lodash/range';

const NumberOfGuests: React.FC = ({ children }) => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.NumberOfGuests' });
  const { guestBook, updateGuestBook } = useGuestBookContext();
  const { register, watch, setValue, setFocus, handleSubmit } = useForm({ defaultValues: guestBook });
  const numberOfGuestsOptions = range(1, /* reservation.roomGroup.maximumGuests */ 15);

  const [numberOfGuests, resetNumberOfGuests] = watch(['numberOfGuests', 'resetNumberOfGuests']);

  const onSubmit = (form) => {
    const { numberOfGuests } = form;
    setValue('resetNumberOfGuests', false);
    updateGuestBook({ numberOfGuests });
  };

  const resetGuests = () => {
    setValue('resetNumberOfGuests', true);
    setFocus('numberOfGuests');
  }

  const modifyNumberOfGuests = (
    <Button
      endIcon={<ChevronRightIcon />}
      onClick={resetGuests}
    >
      {t('update')}
    </Button>
  );

  if (!guestBook) { return; }

  return (<>
    {numberOfGuests && !resetNumberOfGuests? (<>
      <List>
        <ListItem disableGutters disablePadding
          // secondaryAction={modifyNumberOfGuests}
        >
          <ListItemText>
            {t('total', { count: numberOfGuests })}
            <Trans t={t} i18nKey="registered" count={guestBook.registeredGuests}
              components={[
                <Typography component="span" color="secondary.main" sx={{ ml: 2 }}>
                  Registerd: <Typography component="span" fontWeight={700}>{guestBook.registeredGuests}</Typography>
                </Typography>
              ]}
            >
            </Trans>
          </ListItemText>
        </ListItem>
      </List>
      {children}
    </>) : (<>
      <Container sx={{ mt: 2, mb: 3 }}>
        <Typography>{t('description')}</Typography>
        <Caption component="p" mb={2}>{t('caption')}</Caption>
        <Grid container>
          <Grid item xs={6}>
            <Select
              native
              fullWidth
              defaultValue={guestBook.numberOfGuests}
              inputProps={register('numberOfGuests')}
              onChange={handleSubmit(onSubmit)}
            >
              {numberOfGuestsOptions.map(n => <option key={n} value={n}>{t('numberOfGuestsWithCount', { count: n })}</option>)}
            </Select>
          </Grid>
        </Grid>
      </Container>
    </>)}
  </>);
};

export default NumberOfGuests;

