import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
} from '@mui/material';
import logoSVG from "@src/assets/logo.svg";
import LanguageSwitcher from '@src/components/ui/LanguageSwitcher';

const Header = ({ logo, children, ...props }) => {
  return (<>
    <LanguageSwitcher />
    <AppBar position="sticky" color="white" elevation={2} {...props}>
      <Toolbar>
        <Container maxWidth="sm">
          <Box display="flex" flexDirection="column" textAlign={logo ? "center" : "left"} mx={logo ? "auto" : 0}>
            {logo && (<>
              <Box
                src={logoSVG}
                alt="Rakuten STAY"
                height="25px"
                component="img"
                display="block"
              />
              <Typography component="div" variant="caption" lineHeight="1">GUEST BOOK</Typography>
            </>)}
            {children}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  </>);
};

export default Header;

