import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SubmitButton from '@src/components/buttons/SubmitButton';
import StandardButton from '@src/components/buttons/StandardButton';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inputRef } from '@src/util/form';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useForm } from 'react-hook-form';
import Shake from '@src/components/ui/Shake';

const Form: React.FC = ({ authCode, ...props }) => {
  const { t, i18n } = useTranslation("common", { keyPrefix: "components.LockScreen.Form" });
  const { register, setError, handleSubmit, formState: { errors, isSubmitting } } = useForm();
  const { unlock } = useReservationContext();
  const navigate = useNavigate();
  const [shake, setShakeStatus] = useState(false);

  const onSubmit = (query) => {
    const { language } = i18n;
    setShakeStatus(false);

    return unlock({ ...query, language }).then(({ guestBooks }) => {
      setShakeStatus(!guestBooks);

      if (!guestBooks) {
        setError("code", { type: 'custom', message: t('invalid') });
      }
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      {authCode ? (
        <input type="hidden" {...register('code', { value: authCode })} />
      ) : (
        <FormControl fullWidth>
          <FormLabel sx={{ fontSize: 'body2.fontSize' }}>{t('passCode')}</FormLabel>

          <Shake in={shake}>
            <TextField
              placeholder="●●●●"
              autoFocus
              fullWidth
              required
              inputProps={{ tabIndex: 1, pattern: "\\d*" }}
              InputProps={{
                sx: {
                  fontSize: 'h1.fontSize',
                  'input': { py: 1, letterSpacing: "20px" },
                  'input::placeholder': { fontSize: "10px" },
                  'legend': { fontSize: 'caption.fontSize' },
                }
              }}
              sx={{ bgcolor: '#fff' }}
              {...inputRef(register('code', { required: true }))}
            />
          </Shake>

          <FormHelperText error={!!errors.code?.message}>{errors.code?.message || t('helperText')}</FormHelperText>
        </FormControl>
      )}

      {authCode && (
        <Button
          color="secondary"
          tabIndex={-1}
          startIcon={<ChevronLeftIcon />}
          sx={{ fontSize: 'body2.fontSize' }}
          onClick={() => navigate(-1)}
        >{t('common:back')}</Button>
      )}

      <Box maxWidth={240} mx="auto">
        <SubmitButton
          loading={isSubmitting}
          sx={{ mt: 2 }}
        >{t(authCode ? "register" : "submit")}</SubmitButton>
      </Box>
    </Box>
  );
};

export default Form;

