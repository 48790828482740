import {
  Alert,
  Card,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { nl2br } from '@src/util/string';

const Info: React.FC = (props) => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.Info' });
  const { reservation } = useReservationContext();

  if (!reservation) { return null; }

  let color;
  switch (reservation.checkinStatus) {
  case "submitted":
    color = "secondary";
    break;
  default:
    color = "info";
  }

  let checkinTime = reservation.checkinStartTime.format(
    reservation.checkinIsToday ? 'LT' : 'LLL'
  );
  if (reservation.checkinIsThisYear) {
    checkinTime = checkinTime.replace(new RegExp(reservation.checkinStartTime.format('YYYY') + '[年년 ]?'), '');
  }

  let message = t(`${reservation.checkinStatus}.message`, '', { checkinTime });
  if (!message) { message = t('pre-entry.message'); }

  return (<>
    <Card {...props} sx={{ p: 1, ...props.sx }}>
      <Alert
        icon={false}
        severity="info"
        color={color}
        sx={{
          borderRadius: '8px',
          fontWeight: 700,
          color: `${color}.main`,
        }}
      >
        {message.split("\n\n").map((s, i, a) => (
          <Typography key={i} variant="body2" paragraph={!!a[i+1]}>{nl2br(s)}</Typography>
        ))}
      </Alert>
    </Card>
  </>);
};

export default Info;

