import React from 'react';
import Box from '@mui/material/Box';
import { headShake } from 'react-animations';
import { styled } from '@mui/material/styles';

const Shake = styled(({ in: shake, delay, ...props }) => (
  <Box {...props} />
))(({ theme, delay = 0, in: shake }) => ({
  '@keyframes headShake': headShake,
  animation: shake ? 'headShake' : 'none',
  animationDuration: `${theme.transitions.duration.complex * 2}ms`,
  animationDelay: `${delay}ms`,
}));

export default Shake;
