import React, { useEffect } from 'react';
import SubmitButton from '@src/components/buttons/SubmitButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import Box from '@mui/material/Box';
import Caption from '@src/components/typography/Caption';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import { useMutationAPI } from '@src/util/api';
import { useQuery } from '@src/util/router';

const EKYCStatus: React.FC = () => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.EKYCStatus' });
  const { reservation, reload } = useReservationContext();
  const { guestBook: { primaryGuest } = {}} = useGuestBookContext();
  const { ekyc } = useQuery();

  const { data: session, error, trigger, isMutating } = useMutationAPI(
    `/v1/checkin/ekyc/session`
  );

  useEffect(() => {
    if (ekyc == "completed") {
      trigger({ method: 'GET' }).then(() => reload());
    }
  }, [ekyc]);

  const startSession = () => {
    const body = {
      returnTo: `${location.origin}/${reservation.hashedId}?ekyc=completed`,
    };

    return trigger({ method: 'POST', body }).then(({ url }) => {
      location.href = url;
    });
  };

  if (ekyc && isMutating) {
    return <ListItem><CircularProgress size="24px" /></ListItem>;
  }

  if ((primaryGuest?.ekyc?.status == "submitted") || (session?.status == "submitted")) {
    return (
      <ListItem secondaryAction={
        <Button color="secondary" endIcon={<ChevronRightIcon />} onClick={startSession}>{t('update')}</Button>
      }>
        <Chip icon={<CheckIcon />} label={t('submitted')} sx={{ color: 'secondary.main', fontWeight: 700, bgcolor: 'secondary.bg', '.MuiChip-icon': { color: "secondary.main" } }} />
      </ListItem>
    );
  }

  if (ekyc && error) {
    return <Alert severity="error">{t('failed')}</Alert>;
  }

  if (ekyc && session) {
    // ekyc session isn't completed, hence redirect to entry page again.
    location.href = `/${reservation.hashedId}`;
    return;
  }

  return (
    <ListItem>
      <Box>
        <SubmitButton
          startIcon={<PhotoCameraOutlinedIcon />}
          onClick={startSession}
          loading={isMutating}
        >{t('register')}</SubmitButton>
        <Caption component="p" sx={{ mt: 1 }}>{t('description')}</Caption>
      </Box>
    </ListItem>
  );
};

export default EKYCStatus;

