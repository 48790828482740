import ListItemButton from '@mui/material/ListItemButton';
import AddIcon from '@mui/icons-material/Add';

const ListItemAddButton: React.FC = ({ children, sx, ...props }) => {
  return (<>
    <ListItemButton sx={{
      border: (theme) => `1px dashed ${theme.palette.secondary.main}`,
      borderRadius: '8px',
      bgcolor: 'secondary.bg',
      color: 'secondary.main',
      py: 2,
      justifyContent: 'center',
      ...sx
    }} {...props}>
      <AddIcon />
      {children}
    </ListItemButton>
  </>);
};

export default ListItemAddButton;

