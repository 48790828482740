export default {
  "guestBook": "Guest Book",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "Please complete the registration of all guest information before check-in.",
      },
      "submitted": {
        "message": "Pre-registration is ready. Check-in reception starts from {{checkinTime}}.",
      },
      "checked-in": {
        "message": "This reservation has been checked in.\n\nIf you forget your room number or room key, use the QR code or check-in number displayed on this screen to perform the re-display operation on the check-in terminal.",
      },
      "checked-out": {
        "message": "Thank you for staying at Rakuten STAY.\n\nWe look forward to serving you again.",
      },
    },
    "NumberOfGuests": {
      "total": "Total Guests: {{count}}",
      "registered": "<0>Registered: <1>{{count}}</1></0>",
      "description": "Please register the number of guests.",
      "caption": "Register information for all registered guests.",
      "update": "Update",
      "numberOfGuestsWithCount_one": "{{count}} guest",
      "numberOfGuestsWithCount_other": "{{count}} guests",
    },
    "PrimaryGuest": {
      "title": "Primary Guest",
      "register": "Register",
      "update": "Edit",
    },
    "EKYCStatus": {
      "description": "For personal verification during check-in, please register your facial photo.",
      "register": "Register Face",
      "update": "Re-register",
      "submitted": "Facial photo registered",
      "failed": "Failed to retrieve facial photo registration status. Please reload the page.",
    },
    "CheckinCode": {
      "description": "Please proceed with the check-in using this QR code or check-in number at the check-in terminal (SMART CHECK-IN).",
      "label": "Check-in Number",
    },
    "AccompanyingGuests": {
      "title_zero": "Accompanying Guest",
      "title": "Accompanying Guest {{count}}",
      "register": "Register",
      "update": "Edit",
    },
  },
};
