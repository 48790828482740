import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';

const BackButton: React.FC = ({ children, ...props }) => {
  const { t } = useTranslation('common');

  return <Button
    variant="outlined"
    size="large"
    sx={{
      py: 1.5,
      fontWeight: 700,
      fontSize: 'h4.fontSize',
      borderRadius: '30px',
      color: 'text.main',
      bgcolor: 'grey.100',
      borderColor: 'grey.300',
    }}
    {...props}
  >
    <ArrowBackIcon />
    {children || t('back')}
  </Button>;
};

export default BackButton;

