export default {
  "Reservation information": "预订信息",
  "Guest information": "客人信息",
  "cancel": "取消",
  "save": "保存",
  "yes": "是",
  "no": "否",
  "back": "返回",
  "today": "今天",
  "components": {
    "ReservationSummary": {
      "title": "预订详情",
      "failed": "获取预订信息失败。请重新加载页面并重试。",
      "name": "预订者姓名",
      "bookingNumber": "预订编号",
      "property": "住宿设施",
      "numberOfRooms": "房间数",
      "checkin": "办理入住",
      "checkout": "办理退房",
    },
    "LockScreen": {
      "greeting": "欢迎来到Rakuten STAY\n\n请在办理入住前在此处注册客人信息。",
      "found": "找到了$t(today)办理入住的预订。\n\n如果预订信息无误，请继续注册客人信息。",
      "Form": {
        "passCode": "通行码",
        "helperText": "请输入事先通过电子邮件或短信发送的通行码。",
        "invalid": "通行码无效。",
        "submit": "提交",
        "register": "前往注册客人信息",
      },
    },
    "Root": {
      "greeting": "欢迎来到Rakuten STAY",
      "description": "为了继续办理入住手续，请在此处注册客人信息。",
      "Form": {
        "property": "住宿设施",
        "bookingNumber": "预订编号",
        "bookingNumberHelp": "请输入在预订网站上发行的预订编号。预订编号可在确认邮件中找到。",
        "submit": "确认预订",
        "notFound": "未找到匹配的预订。",
      },
    },
    "AnnotationBanner": {
      "p1": "由于此服务目前处于试运营阶段，仅向持有日本国籍或居住在日本的个人开放。",
      "p2": "如果在宾客中有持有外国国籍且在日本没有地址的情况，则将采用传统的办理入住方式，请访问以下链接注册护照并预先注册宾客名单。",
      "preEntry": "宾客名单预先注册（传统方式）",
    },
    "Footer": {
      "privacyPolicy": {
        "label": "个人信息保护政策",
        "href": "https://corp.stay.rakuten.co.jp/privacy-en.html",
      },
      "agreement": {
        "label": "Rakuten STAY 住宿条款",
        "href": "https://stay.rakuten.co.jp/agreement/zh.html"
      },
    },
  },
  "checkinStatus": {
    "pre-entry": "宿泊者信息登记中",
    "submitted": "宿泊者信息登记完成",
    "checked-in": "已办理入住",
    "checked-out": "已办理退房",
  },
};
