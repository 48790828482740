import ApplicationModel from '@src/models/ApplicationModel';

export default class Guest extends ApplicationModel {
  get initialPhoneNumberCountryCode() {
    if (this.phoneNumberCountryCode) {
      return this.phoneNumberCountryCode;
    }

    if (this.countryCode && (this.countryCode != 'NULL')) {
      return this.countryCode;
    }
  }

  get primary() {
    return this.role === 'primary';
  }

  get ready() {
    if (this.primary) {
      return this.ekyc.status === 'submitted' && this.status === 'ready';
    }

    return this.status === 'ready';
  }
}
