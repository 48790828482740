import React from 'react';
import {
  Alert,
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { GuestBookProvider } from '@src/providers/GuestBookProvider';
import Header from '@src/components/ui/Header';
import ReservationSummary from '@src/components/ui/ReservationSummary';
import AnnotationBanner from '@src/components/ui/AnnotationBanner';
import Footer from '@src/components/ui/Footer';
import Info from './Info';
import CheckinCode from './CheckinCode';
import NumberOfGuests from './NumberOfGuests';
import PrimaryGuest from './PrimaryGuest';
import AccompanyingGuests from './AccompanyingGuests';
import LockScreen from '@src/routes/LockScreen';
import LoadingBackdrop from '@src/components/ui/LoadingBackdrop';
import './i18n';

const Entrance: React.FC = () => {
  const { t } = useTranslation('Entrance');
  const { isLoading, reservation, guestBooks } = useReservationContext();

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (reservation && !guestBooks) {
    return <LockScreen />
  }

  return (<>
    <Header logo />

    {reservation?.checkinStatus == "pre-entry" && <AnnotationBanner />}

    <Container maxWidth="sm">
      <Info sx={{ my: 2 }} />

      <CheckinCode sx={{ my: 2 }} />

      {guestBooks?.map((guestBook, index) => (
        <GuestBookProvider key={guestBook.hashedId} guestBook={guestBook}>
          <Card sx={{ my: 2 }}>
            <CardContent>
              <Typography variant="h2">{t('guestBook')}</Typography>
              <NumberOfGuests>
                <PrimaryGuest />
                <AccompanyingGuests />
              </NumberOfGuests>
            </CardContent>
          </Card>
        </GuestBookProvider>
      ))}

      <Card variant="outlined">
        <CardContent>
          <ReservationSummary />
        </CardContent>
      </Card>

      <Footer my={4} />
    </Container>
  </>);
};

export default Entrance;

