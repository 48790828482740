import { useEffect } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import dayjs from 'dayjs';

// <Stack direction="row" useFlexGap flexWrap="wrap" justifyContent="space-between" {...props} />
const Row = (props) => (<>
  <Stack direction="row" spacing={1} justifyContent="space-between" {...props} />
  <Divider sx={{ my: 1 }} />
</>);

const DT = ({ sx, ...props }) => (
  <Typography
    variant="body2"
    color="text.secondary"
    sx={{ py: 0.5, ...sx }}
    {...props}
  />
);

const DD = ({ sx, ...props }) => (
  <Typography
    variant="body2"
    sx={{ py: 0.5, ...sx }}
    {...props}
  />
);

const ReservationSummary = (props) => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'components.ReservationSummary' });
  const { reservation, mutate, error, isLoading } = useReservationContext();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    console.error(error);
    return <Alert severity="warning">{t('failed')}</Alert>;
  }

  if (!reservation) {
    return;
  }

  return (<Box {...props}>
    <Typography variant="h2" sx={{ mb: 1.5 }}>{t('title')}</Typography>

    {(reservation.primaryGuest?.name || reservation.name) && (
      <Row>
        <DT>{t('name')}</DT>
        <DD>{reservation.primaryGuest?.name || reservation.name}</DD>
      </Row>
    )}

    <Row>
      <DT>{t('bookingNumber')}</DT>
      <DD>{reservation.bookingNumber}</DD>
    </Row>

    <Row>
      <DT>{t('property')}</DT>
      <DD>{reservation.property.localized('title')}</DD>
    </Row>

    <Row>
      <DT>{t('numberOfRooms')}</DT>
      <DD>{reservation.numberOfRooms}</DD>
    </Row>

    <Row>
      <DT>{t('checkin')}</DT>
      <DD>{dayjs(reservation.checkin).format('LL (dd)')}</DD>
    </Row>

    <Row>
      <DT>{t('checkout')}</DT>
      <DD>{dayjs(reservation.checkout).format('LL (dd)')}</DD>
    </Row>
  </Box>);
};

export default ReservationSummary;
