import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import StandardButton from '@src/components/buttons/StandardButton';

const SubmitButton: React.FC = ({ loading, ...props }) => {
  if (loading) {
    if (props.startIcon) {
      props = {
        ...props,
        startIcon: <CircularProgress size={10} />
      };
    } else {
      props = {
        ...props,
        endIcon: <CircularProgress size={10} />
      };
    }
  }

  return <StandardButton
    type="submit"
    color="primary"
    variant="contained"
    disabled={loading}
    fullWidth
    {...props}
    sx={{
      background: (theme) => theme.palette.secondary.gradient,
      ...props.sx
    }}
  />;
};

export default SubmitButton;

