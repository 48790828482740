import ApplicationModel from '@src/models/ApplicationModel';
import Property from '@src/models/Property';
import GuestBook from '@src/models/GuestBook';
import dayjs from 'dayjs';

export default class Reservation extends ApplicationModel {
  constructor(attrs = {}) {
    super(attrs);
    this.property = new Property(this.property || {});
    this.castDateAttributes('checkin', 'checkout');
    this.checkout ||= dayjs(this.checkin).add(1, 'day').toDate();
    this.guestBooks = this.guestBooks?.map(obj => new GuestBook(obj));

    this.checkinTime &&= dayjs(this.checkinTime).tz(this.property.timeZone);
    this.checkinStartTime = dayjs(this.checkin).tz(this.property.timeZone).hour(15).minute(0);
  }

  get lengthOfStay() {
    return this.checkout.diff(this.checkin, 'day') - 0;
  }

  get primaryGuestBook() {
    return this.guestBooks?.[0];
  }

  get primaryGuest() {
    return this.primaryGuestBook?.guests?.[0];
  }

  get checkinCodeSVG() {
    return `/${this.hashedId}/${this.primaryGuestBook?.hashedId}.svg`;
  }

  get checkinIsToday() {
    const { localTime } = this.property;
    return this.checkinStartTime.isSame(localTime, 'day');
  }

  get checkinIsThisYear() {
    const { localTime } = this.property;
    return this.checkinStartTime.isSame(localTime, 'year');
  }

  findGuestBook({ hashedId }) {
    return this.guestBooks?.find(book => book.hashedId == hashedId);
  }

  updateGuestBook(guestBook) {
    this.findGuestBook(guestBook)?.setAttributes(guestBook);
  }
}
