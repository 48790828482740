import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';

const CheckinCode: React.FC = () => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.CheckinCode' });
  const { reservation } = useReservationContext();

  if (reservation?.checkinCode) {
    return (
      <Card sx={{
        background: (theme) => theme.palette.secondary.gradient,
        color: 'secondary.contrastText'
      }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">{t('label')}:</Typography>
            <Typography variant="h3" fontWeight={700}>{reservation.checkinCode}</Typography>
          </Box>

          <Box
            component="img"
            src={reservation.checkinCodeSVG}
            borderRadius="8px"
            bgcolor="#fff"
            my={2}
            mx="auto"
            display="block"
            width="144px"
            height="144px"
          />

          <Typography variant="caption" paragraph mb={0}>{t('description')}</Typography>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default CheckinCode;

