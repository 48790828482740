import Button from '@mui/material/Button';

const StandardButton: React.FC = (props) => {
  return <Button
    fullWidth
    color="darkGrey"
    size="large"
    variant="outlined"
    {...props}
    sx={{
      py: 1.5,
      fontSize: 'h4.fontSize',
      borderRadius: '30px',
      ...props.sx
    }}
  />;
};

export default StandardButton;

