import { createTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { alpha, darken } from '@mui/material/styles';
import { isTouchDevice } from '@src/util/device';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const defaultShadows = createTheme({}).shadows;

const themeColors = {
  primary: '#333',
  secondary: '#507763',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#DF0101',
  info: '#506377',
  black: '#223354',
  white: '#252525',
  primaryAlt: '#090A0C',
  trueWhite: '#ffffff',
  trueBlack: '#000000',
};

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tablet: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [
      '"Noto Sans JP"',
      '"Helvetica Neue"',
      '"Helvetica"',
      '"Hiragino Sans"',
      '"Hiragino Kaku Gothic ProN"',
      '"Arial"',
      '"Yu Gothic"',
      '"Meiryo"',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: "1.6rem",
    },
    h2: {
      fontSize: "1.3rem",
    },
    h3: {
      fontSize: "1.2rem",
    },
    h4: {
      fontSize: "1rem",
    },
    h5: {
      fontSize: "0.875rem",
    },
    h6: {
      fontSize: "0.75rem",
    },
  },
  shadows: defaultShadows.map(s => s.replace(/0,0,0,/g, '34,51,84,').replace(/0.2/, '0.1').replace(/0.14/, '0.04').replace(/0.12/, '0.02')),
  palette: {
    primary: {
      main: alpha(themeColors.trueBlack, 0.8),
      light: alpha(themeColors.trueBlack, 0.6),
      dark: alpha(themeColors.trueBlack, 0.9),
      contrastText: '#fff'
    },
    brand: {
      main: '#00B900',
      light: '#2AC72A',
      dark: '#009500',
      contrastText: '#fff'
    },
    rakuten: {
      main: '#BF0000',
      light: '#E61717',
      dark: '#930A0A',
      contrastText: '#fff',
      gradient: `linear-gradient(90deg, #BF0000 0%, #630000 100%)`,
    },
    secondary: {
      bg: alpha('#669980', 0.1),
      light: alpha(themeColors.secondary, 0.75),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2),
      gradient: `linear-gradient(90deg, ${themeColors.secondary} 0%, ${darken(themeColors.secondary, 0.2)} 100%)`,
    },
    // primary: {
    //   bg: alpha(themeColors.primary, 0.1),
    //   light: alpha(themeColors.primary, 0.75),
    //   main: themeColors.primary,
    //   dark: darken(themeColors.primary, 0.2)
    // },
    success: {
      bg: alpha(themeColors.success, 0.1),
      light: alpha(themeColors.success, 0.75),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2)
    },
    warning: {
      bg: alpha(themeColors.warning, 0.1),
      light: alpha(themeColors.warning, 0.75),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2)
    },
    error: {
      bg: alpha(themeColors.error, 0.1),
      light: alpha(themeColors.error, 0.75),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2)
    },
    info: {
      bg: alpha(themeColors.info, 0.1),
      light: alpha(themeColors.info, 0.75),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2)
    },
    white: {
      main: alpha(themeColors.trueWhite, 0.95),
      light: themeColors.trueWhite,
      dark: alpha(themeColors.white, 0.02),
      contrastText: themeColors.black,
      alpha: {
        5: alpha(themeColors.trueWhite, 0.02),
        10: alpha(themeColors.trueWhite, 0.1),
        20: alpha(themeColors.trueWhite, 0.2),
        30: alpha(themeColors.trueWhite, 0.3),
        50: alpha(themeColors.trueWhite, 0.5),
        70: alpha(themeColors.trueWhite, 0.7),
        80: alpha(themeColors.trueWhite, 0.8),
        90: alpha(themeColors.trueWhite, 0.9),
        100: themeColors.trueWhite,
      },
    },
    dark: {
      main: alpha(themeColors.trueBlack, 0.8),
      dark: alpha(themeColors.trueBlack, 0.9),
      contrastText: themeColors.trueWhite,
    },
    darkGrey: {
      main: grey[600],
      dark: grey[800],
    },
    grey: {
      main: grey[300],
      dark: grey[400],
      contrastText: grey[700],
      alpha: {
        5: alpha(themeColors.trueBlack, 0.02),
        10: alpha(themeColors.trueBlack, 0.1),
        20: alpha(themeColors.trueBlack, 0.2),
        30: alpha(themeColors.trueBlack, 0.3),
        50: alpha(themeColors.trueBlack, 0.5),
        70: alpha(themeColors.trueBlack, 0.7),
        80: alpha(themeColors.trueBlack, 0.8),
        90: alpha(themeColors.trueBlack, 0.9),
        100: themeColors.trueBlack,
      }
    },
    background: {
      default: '#f5f5f5',
      light: '#fbfbf9',
      grey: '#666',
      dark: '#11192a',
      selected: '#EAF1ED',
      annotation: '#FDF9E2',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        message: {
          padding: '6px 0',
        }
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: !isTouchDevice(),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.88rem',
          marginBottom: '4px',
        },
      },
    },
  },
});

export default theme;

