export default {
  "guestBook": "宾客登记簿",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "请在办理入住手续前完成所有客人信息的登记。",
      },
      "submitted": {
        "message": "办理入住手续已准备就绪。\n办理入住手续接待开始时间为{{checkinTime}}。",
      },
      "checked-in": {
        "message": "此预订已办理入住手续。\n\n如果您忘记了房间号码或房卡，请使用此屏幕上显示的二维码或入住号码，在办理入住终端上执行它们的重新显示操作。",
      },
      "checked-out": {
        "message": "感谢您入住Rakuten STAY。\n\n期待再次为您服务。",
      },
    },
    "NumberOfGuests": {
      "total": "宾客总人数：{{count}}人",
      "registered": "<0>已登记：<1>{{count}}人</1></0>",
      "description": "请登记宾客人数。",
      "caption": "登记所有已登记宾客的信息。",
      "update": "更新",
      "numberOfGuestsWithCount": "{{count}} 位住客",
    },
    "PrimaryGuest": {
      "title": "主要宾客",
      "register": "登记",
      "update": "编辑",
    },
    "EKYCStatus": {
      "description": "为确保办理入住时的个人验证，请登记您的面部照片。",
      "register": "注册面部",
      "update": "重新注册",
      "submitted": "面部照片已登记",
      "failed": "无法检索面部照片登记状态。请重新加载页面。",
    },
    "CheckinCode": {
      "description": "请在此登记终端（SMART CHECK-IN）上使用此二维码或登记号进行登记手续。",
      "label": "登记号",
    },
    "AccompanyingGuests": {
      "title_zero": "陪同宾客",
      "title": "陪同宾客 {{count}}",
      "register": "登记",
      "update": "编辑",
    },
  },
};
