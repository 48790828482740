import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import H3 from '@src/components/typography/H3';
import ListItemAddButton from '@src/components/buttons/ListItemAddButton';
import { Link } from 'react-router-dom';

const AccompanyingGuests: React.FC = () => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.AccompanyingGuests' });
  const { reservation } = useReservationContext();
  const { guestBook: { companions, hashedId, primaryGuest } = {}} = useGuestBookContext();

  // if (primaryGuest?.ekyc?.status != "submitted") {
  //   return null
  // }

  return (<>
    <List>
      {companions.map((guest, index) => (<Fragment key={index}>
        <H3 component="li" sx={{ mb: 1 }}>{t('title', { count: companions.length > 1 ? index + 1 : 0 })}</H3>
        {!guest.status || (guest.status == 'unready') ? (<>
          <ListItem disablePadding>
            <ListItemAddButton component={Link} to={`/${reservation.hashedId}/${hashedId}/guests/${index + 1}`}>
              {t('register')}
            </ListItemAddButton>
          </ListItem>
        </>) : (<>
          <ListItem
            secondaryAction={
              <Button color="secondary" endIcon={<ChevronRightIcon />} component={Link} to={`/${reservation.hashedId}/${hashedId}/guests/${index + 1}`}>{t('update')}</Button>
            }
          >
            <ListItemText>
              <Typography fontWeight={700}>{guest.name}</Typography>
            </ListItemText>
          </ListItem>
        </>)}
      </Fragment>))}
    </List>
  </>);
};

export default AccompanyingGuests;

