export default {
  "guestBook": "宿泊者名簿",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "チェックインまでに全ての宿泊者情報の登録を完了してください。",
      },
      "submitted": {
        "message": "チェックインの準備ができました。\nチェックイン受付開始時間は{{checkinTime}}からです。",
      },
      "checked-in": {
        "message": "この予約はチェックイン済みです。\n\n部屋番号およびルームキーをお忘れの場合は、この画面に表示されている二次元コードまたはチェックイン番号を使って、チェックイン端末上でそれらの再表示操作をしてください。",
      },
      "checked-out": {
        "message": "この度は、Rakuten STAYにご宿泊いただき、誠にありがとうございます。\n\nまたのご利用をお待ちしております。",
      },
    },
    "NumberOfGuests": {
      "total": "宿泊人数: {{count}}人",
      "registered": "<0>登録済み: <1>{{count}}人</1></0>",
      "description": "宿泊人数を登録してください。",
      "caption": "登録した宿泊人数全員分の宿泊者名簿情報をご登録いただきます。",
      "update": "変更",
      "numberOfGuestsWithCount": "{{count}}名",
    },
    "PrimaryGuest": {
      "title": "代表者",
      "register": "登録する",
      "update": "編集",
    },
    "EKYCStatus": {
      "description": "チェックイン時の本人確認のために、顔画像の登録をお願いします。",
      "register": "顔登録",
      "update": "再登録",
      "submitted": "顔画像登録済み",
      "failed": "顔画像の登録状況を取得できませんでした。ページの再読込みをお願いします。"
    },
    "CheckinCode": {
      "description": "チェックイン端末（SMART CHECK-IN）でこちらの二次元コードまたは、チェックイン番号を使用してチェックイン手続きをしてください。",
      "label": "チェックイン番号",
    },
    "AccompanyingGuests": {
      "title_zero": "同行者",
      "title": "同行者 {{count}}",
      "register": "登録する",
      "update": "編集",
    },
  },
};
