import Typography from '@mui/material/Typography';

const Caption = (props) => (
  <Typography
    variant="caption"
    color="textSecondary"
    {...props}
  />
);

export default Caption;

