export default {
  "title": {
    "primary" : "代表者",
    "companion" : "同行者{{count}}人目 登録",
  },
  "section1": {
    "title": {
      "primary": "代表者は日本人もしくは、日本国内に住所を有していますか？",
      "companion": "登録対象者は日本人もしくは、日本国内に住所を有していますか？",
    },
    "caption": "日本人ではない、かつ日本国内に住所を有さない方はパスポートの登録が必要です。"
  },
  "section2": {
    "title": {
      "primary" : "代表者の本人情報",
      "companion" : "同行者{{count}}人目の本人情報",
    },
  },
  "section3": {
    "title": "チェックイン完了通知メール設定",
    "description": "チェックイン完了時にメールでお部屋の入室方法（部屋入室用の暗証番号、部屋番号）を受けとることができます。",
    "caption": "これらの情報は、チェックイン端末で一時的に表示できますが忘れてしまった時のためにメールで受け取っておくと安心です。この設定は代表者のみ設定可能です。"
  },
  "guest": {
    "name": {
      "label": "氏名（必須）",
      "placeholder": "楽天 タロウ",
      "errors": {
        "required": "氏名を入力してください",
      },
    },
    "address": {
      "label": "住所（必須）",
      "placeholder": "東京都世田谷区玉川1-14-1",
      "errors": {
        "required": "住所を入力してください",
      },
    },
    "phoneNumber": {
      "label": "連絡先電話番号（必須）",
      "defaultCountry": "jp",
      "errors": {
        "required": "連絡先電話番号を入力してください",
        "invalid": "正しい電話番号を入力してください",
      },
    },
    "checkinNotification": {
      "label": "メールを受信しますか？（必須）",
      "errors": {
        "required": "選択してください",
      },
    },
    "email": {
      "label": "メールアドレス（必須）",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "メールアドレスを入力してください",
        "invalid": "正しいメールアドレスを入力してください",
      },
    },
  },
  "sameWithPrimaryGuest": "代表者と同じ",
  "register": "登録する",
};
