import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  Container,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import { Controller, useForm } from 'react-hook-form';
import Header from '@src/components/ui/Header';
import Caption from '@src/components/typography/Caption';
import RadioButton from '@src/components/buttons/RadioButton';
import SubmitButton from '@src/components/buttons/SubmitButton';
import StandardButton from '@src/components/buttons/StandardButton';
import BackButton from '@src/components/buttons/BackButton';
import AnnotationBanner from '@src/components/ui/AnnotationBanner';
import { inputRef, emailRegex } from '@src/util/form';
import { ScrollToTop } from '@src/util/router';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import PhoneNumberInput from '@src/components/ui/PhoneNumberInput';
import Shake from '@src/components/ui/Shake';
import pick from 'lodash/pick';
import './i18n';

const GuestForm: React.FC = ({ primary }) => {
  const { t } = useTranslation('GuestForm');
  const { reservation } = useReservationContext();
  const { guestBook, updateGuestBook } = useGuestBookContext();
  const { primaryGuest } = guestBook;
  const { guestIndex } = useParams();
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [phoneNumberInputKey, setPhoneNumberInputKey] = useState(1);

  let guest;

  if (primary) {
    guest = primaryGuest
  } else {
    guest = guestBook.guests[guestIndex - 0];
  }

  const {
    register,
    setValue,
    setFocus,
    clearErrors,
    formState: { isSubmitting, errors },
    handleSubmit,
    control,
    watch
  } = useForm({ defaultValues: guest });

  const navigate = useNavigate();

  const [countryCode, address, phoneNumber, phoneNumberCountryCode, checkinNotification] = watch([
    'countryCode', 'address', 'phoneNumber', 'phoneNumberCountryCode', 'preference.checkinNotification'
  ]);

  useEffect(() => {
    if (!guest.countryCode) { setValue('countryCode', primaryGuest.countryCode); }
    if (!guest.address) { setValue('address', primaryGuest.address); }
    if (!guest.phoneNumber) { setValue('phoneNumber', primaryGuest.phoneNumber); }
    if (!guest.phoneNumberCountryCode) { setValue('phoneNumberCountryCode', primaryGuest.phoneNumberCountryCode); }
  }, []);

  const onSubmit = (form) => {
    const guests = [pick(
      form,
      'hashedId',
      'countryCode',
      'name',
      'address',
      'phoneNumber',
      'phoneNumberCountryCode',
      'preference',
      'email'
    )];

    return updateGuestBook({ numberOfGuests: guestBook.numberOfGuests, guests }).then(() => {
      navigate(`/${reservation.hashedId}`);
    }).catch((err) => {
      // console.log(err);
      // Show toast
    });
  };

  const handleAddressCopy = ({ currentTarget: { checked } }) => {
    if (checked) {
      setValue('address', primaryGuest.address);
      clearErrors('address');
    } else {
      setValue('address', '');
      setFocus('address');
    }
  };

  const handlePhoneNumberCopy = ({ currentTarget: { checked } }) => {
    if (checked) {
      setValue('phoneNumber', primaryGuest.phoneNumber);
      setValue('phoneNumberCountryCode', primaryGuest.phoneNumberCountryCode);
      clearErrors('phoneNumber');
    } else {
      setValue('phoneNumber', '');
      setFocus('phoneNumber');
    }

    setPhoneNumberInputKey((current) => current + 1);
  };

  const handlePhoneNumberCountryChange = (code) => {
    if (code === phoneNumberCountryCode) {
      return;
    }

    setValue('phoneNumberCountryCode', code.toLowerCase());
    setValue('phoneNumber', '');
    setPhoneNumberInputKey((current) => current + 1);
    setFocus('phoneNumber');

    if (countryCode == 'NULL') {
      setValue('countryCode', code.toLowerCase());
    }
  };

  return (<>
    <ScrollToTop />

    <Header color="dark">
      <Typography component="h1" fontWeight={700}>{t(primary ? 'title.primary' : 'title.companion', { count: guestIndex })}</Typography>
    </Header>

    <Container maxWidth="sm" disableGutters component="form" onSubmit={handleSubmit(onSubmit)} sx={{ bgcolor: "background.paper"}}>
      <Container sx={{ py: 3 }}>
        <Typography component="h2" variant="h4" fontWeight={700} gutterBottom>{t(`section1.title.${primary ? 'primary' : 'companion'}`)}</Typography>
        <Caption paragraph>{t('section1.caption')}</Caption>

        <Controller
          render={
            ({ field }) => <RadioGroup {...field}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <RadioButton
                    label={t('no')}
                    value={"NULL"}
                    fullWidth
                    selected={countryCode && (countryCode != 'jp')}
                    onClick={() => (!countryCode || (countryCode == 'jp')) && setValue('countryCode', 'NULL')}
                  />
                </Grid>

                <Grid item xs={6}>
                  <RadioButton
                    label={t('yes')}
                    value={"jp"}
                    fullWidth
                    selected={countryCode == 'jp'}
                    onClick={() => setValue('countryCode', 'jp')}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          }
          control={control}
          name="countryCode"
          defaultValue={guest.countryCode || null}
          rules={{ required: true }}
        />
      </Container>

      <Divider />

      {countryCode && (countryCode !== 'jp') && (<>
        <AnnotationBanner />
        <Divider />
        <Container sx={{ py: 3 }}>
          <BackButton fullWidth type="submit" />
        </Container>
        <Divider />
      </>)}

      {countryCode === 'jp' && (<>
        <Container sx={{ py: 3 }}>
          <Typography component="h2" variant="h4" fontWeight={700} paragraph>{t(`section2.title.${primary ? 'primary' : 'companion'}`, { count: guestIndex })}</Typography>
          <FormControl variant="outlined" fullWidth error={!!errors.name}>
            <FormLabel>{t('guest.name.label')}</FormLabel>
            <OutlinedInput
              placeholder={t('guest.name.placeholder')}
              defaultValue={guest.name}
              {...inputRef(register('name', { required: t('guest.name.errors.required') }))}
            />
            {errors.name && <FormHelperText>{errors.name.message}</FormHelperText>}
          </FormControl>

          <FormControl variant="outlined" fullWidth sx={{ mt: 3 }} error={!!errors.address}>
            <FormLabel>{t('guest.address.label')}</FormLabel>
            <OutlinedInput
              placeholder={t('guest.address.placeholder')}
              defaultValue={guest.address}
              {...inputRef(register('address', { required: t('guest.address.errors.required') }))}
            />
            {errors.address && <FormHelperText>{errors.address.message}</FormHelperText>}
          </FormControl>

          {!primary && (
            <FormControlLabel
              componentsProps={{ typography: { variant: 'body2' }}}
              control={<Checkbox />}
              label={t('sameWithPrimaryGuest')}
              onChange={handleAddressCopy}
              checked={address === primaryGuest.address}
            />
          )}

          <FormControl variant="outlined" fullWidth sx={{ mt: 3 }} error={!!errors.phoneNumber}>
            <FormLabel>{t('guest.phoneNumber.label')}</FormLabel>

            <Controller
              render={({ field }) => (
                <PhoneNumberInput
                  key={phoneNumberInputKey}
                  initialValue={guest.phoneNumber}
                  onChangeNumber={(value) => setValue('phoneNumber', value)}
                  onChangeCountry={handlePhoneNumberCountryChange}
                  onChangeValidity={(valid) => setPhoneNumberValid(valid)}
                  initOptions={{
                    initialCountry: guest.initialPhoneNumberCountryCode || t('guest.phoneNumber.defaultCountry'),
                    utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js",
                  }}
                  {...field}
                />
              )}
              control={control}
              name="phoneNumber"
              defaultValue={guest.phoneNumber || null}
              rules={{
                required: t('guest.phoneNumber.errors.required'),
                validate: {
                  invalid: () => phoneNumberValid || t('guest.phoneNumber.errors.invalid')
                }
              }}
            />
            {errors.phoneNumber && <FormHelperText>{errors.phoneNumber.message}</FormHelperText>}
          </FormControl>

          {!primary && (
            <FormControlLabel
              componentsProps={{ typography: { variant: 'body2' }}}
              control={<Checkbox />}
              label={t('sameWithPrimaryGuest')}
              onChange={handlePhoneNumberCopy}
              checked={phoneNumber === primaryGuest.phoneNumber}
            />
          )}
        </Container>

        {primary && (<>
          <Divider />

          <Container sx={{ py: 3 }}>
            <Typography component="h2" variant="h4" fontWeight={700} paragraph>{t('section3.title')}</Typography>
            <Typography variant="body1" gutterBottom>{t('section3.description')}</Typography>
            <Caption paragraph>{t('section3.caption')}</Caption>

            <FormControl variant="outlined" fullWidth error={!!errors.preference?.checkinNotification}>
              <FormLabel>{t('guest.checkinNotification.label')}</FormLabel>
              <Controller
                render={
                  ({ field }) => <RadioGroup {...field}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <RadioButton
                          label={t('no')}
                          value={"disabled"}
                          fullWidth
                          selected={checkinNotification == 'disabled'}
                          onClick={() => setValue('preference.checkinNotification', 'disabled')}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <RadioButton
                          label={t('yes')}
                          value={"enabled"}
                          fullWidth
                          selected={checkinNotification == 'enabled'}
                          onClick={() => setValue('preference.checkinNotification', 'enabled')}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                }
                control={control}
                name="preference.checkinNotification"
                defaultValue={guest.preference?.checkinNotification || null}
                rules={{ required: t('guest.checkinNotification.errors.required') }}
              />

              <Shake in={!checkinNotification && !!errors.preference?.checkinNotification} key={new Date}>
                <FormHelperText>{!checkinNotification && errors.preference?.checkinNotification?.message}</FormHelperText>
              </Shake>
            </FormControl>

            {checkinNotification == 'enabled' && (
              <FormControl variant="outlined" fullWidth sx={{ mt: 3 }} error={!!errors.email}>
                <FormLabel>{t('guest.email.label')}</FormLabel>
                <OutlinedInput
                  autoFocus={!guest.email}
                  type="email"
                  placeholder={t('guest.email.placeholder')}
                  defaultValue={guest.email || reservation.email}
                  {...inputRef(register('email', {
                    required: t('guest.email.errors.required'),
                    pattern: emailRegex
                  }))}
                />
                {errors.email && <FormHelperText>{errors.email.message || t('guest.email.errors.invalid')}</FormHelperText>}
              </FormControl>
            )}
          </Container>
        </>)}

        <Divider />

        <Container sx={{ py: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <StandardButton
                component={RouterLink}
                to={`/${reservation.hashedId}`}
                tabIndex={-1}
                sx={{ fontSize: 'body2.fontSize', px: 1 }}
              >{t('back')}</StandardButton>
            </Grid>

            <Grid item xs={8}>
              <SubmitButton loading={isSubmitting}>{t('register')}</SubmitButton>
            </Grid>
          </Grid>
        </Container>
      </>)}

      <Divider />
    </Container>
  </>);
};

export default GuestForm;
