export default {
  "title": {
    "primary": "주 투숙객",
    "companion": "동반 투숙객 {{count}} 등록",
  },
  "section1": {
    "title": {
      "primary": "주 투숙객은 일본 국적이거나 일본에 주소가 있습니까?",
      "companion": "등록 대상자는 일본 국적이거나 일본에 주소가 있습니까?",
    },
    "caption": "일본 국적이 아니거나 일본에 주소가 없는 경우, 여권 등록이 필요합니다.",
  },
  "section2": {
    "title": {
      "primary": "주 투숙객 개인 정보",
      "companion": "동반 투숙객 {{count}}의 개인 정보",
    },
  },
  "section3": {
    "title": "체크인 완료 알림 이메일 설정",
    "description": "체크인이 완료되면 객실 입장 방법 (객실 입장 PIN, 객실 번호)에 대한 정보를 이메일로 받을 수 있습니다.",
    "caption": "이 정보는 일시적으로 체크인 단말에 표시될 수 있지만, 잊어버릴 경우를 대비하여 이메일로 받는 것이 안심입니다. 이 설정은 주 투숙객에게만 가능합니다.",
  },
  "guest": {
    "name": {
      "label": "이름 (필수)",
      "placeholder": "라쿠텐 타로",
      "errors": {
        "required": "이름을 입력하세요",
      },
    },
    "address": {
      "label": "주소 (필수)",
      "placeholder": "도쿄 도 세타가야구 타마가와 1-14-1",
      "errors": {
        "required": "주소를 입력하세요",
      },
    },
    "phoneNumber": {
      "label": "연락처 전화번호 (필수)",
      "defaultCountry": "ko",
      "errors": {
        "required": "연락처 전화번호를 입력하세요",
        "invalid": "올바른 전화번호를 입력하세요",
      },
    },
    "checkinNotification": {
      "label": "이메일을 수신하시겠습니까? (필수)",
      "errors": {
        "required": "선택하세요",
      },
    },
    "email": {
      "label": "이메일 주소 (필수)",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "이메일 주소를 입력하세요",
        "invalid": "올바른 이메일 주소를 입력하세요",
      },
    },
  },
  "sameWithPrimaryGuest": "주 투숙객과 동일",
  "register": "등록",
};
