import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from "@src/assets/logo.svg";

const Header: React.FC = (props) => (
  <Box align="center" {...props}>
    <Box
      src={logo}
      alt="Rakuten STAY"
      height="60px"
      component="img"
      display="block"
      mx="auto"
    />
    <Typography component="div" variant="h1" fontWeight={700} lineHeight={1} color="text.secondary" mt={-0.5}>
      GUEST BOOK
    </Typography>
  </Box>
);

export default Header;

